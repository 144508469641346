/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */

@import "Global/Diseño/variables";

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  // display: none;  /* Ocultar scroll */
}

body::-webkit-scrollbar-thumb {
  background: lighten($color: $color-primary, $amount: 10);
  border-radius: 4px;

  &:hover {
    background: $color-primary;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  &:active {
    background-color: darken($color: $color-primary, $amount: 8);
  }
}

.cursor {
  cursor: pointer;
}

input, textarea {
  &:focus {
    box-shadow: none;
    outline: none;
    border-color: darken($color: $color-primary, $amount: 5)
  }
}

.btn:focus {
  box-shadow: none;
}

.check {
  // margin-left: 21px;
  font-family: $font-primary;
  font-size: 15px;
  color: $color-quinto;
  a {
    font-family: $font-primary;
    font-size: 15px;
    color: $color-quinto;
    font-weight: bold;
    text-decoration: none;
  }
  a:hover {
    text-decoration: 1px solid $color-quinto;
  }
}

// .margenes {
//   // separacion de los componentes y los orillos (deracha e izquierda) del documento.
//   margin-left: 78px;
//   margin-right: 78px;
// }

.margenesQ{
  margin: 0 5% 0 5%;
}

.separacion {
  // separacion entre los componentes del documento
  margin-top: 5%;
}
.separacionTituloContenido {
  // separacion entre el titulo y el contenido del componente
  margin-bottom: 40px;
}
.activeP {
  color: #e77817 !important;
  font-weight: bold;
}

.error {
  font-style: italic;
  font-size: 13px;
  margin-bottom: 15px;
  margin-top: -10px;
  margin-left: 48px;
}

.errorG {
  font-style: italic;
  font-size: 13px;
  right: 0px;
}
.icono {
  img {
    height: 16px;
    width: 16px;
  }
}
.construccion {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
  // background-color: turquoise;
  // width: 100%;
  img {
    width: 40vmax;
  }
}

a{
  text-decoration: none;
}

// --------------------------- Estilos Generales --------------------------- //

.titlePrimary{
  font-family: $font-primary;
  font-size: 34px;
  // font-weight: bold;
  color: $color-primary;
  text-align: center;
}
.titleSecundary{
  font-family: $font-primary;
  font-size: 34px;
  font-weight: bold;
}
.parrafo{
  font-family: $font-secundary;
  font-size: 15px;
}

// @media screen and (min-width: 320px) and (max-width: 991px) {
//   .margenes {
//     margin-left: 0;
//     margin-right: 0;
//   }
// }

.resaltar {
  color: $color-primary;
}

.titulo {
  display: flex;
  align-items:center;
  align-content: center;
  margin-bottom: 15px;
  background: $color-background;
  border-bottom-right-radius: 30px;
  position: relative;
  display: inline-block;
  padding-right: 20px;

  .linea {
    width: $ancho-linea;
    background-color: $color-primary;
    height: 100%;
    position: absolute;
    margin-left: 5px;
  }

  h2 {
    position: relative;
    font-family: $font-Roboto;
    color: $color-fuentes;
    font-size: 20px;
    margin: 10px 0px;
    padding-left: 5vw;
  }

  .resaltar {
    color: $color-primary;
  }

}

p {
  text-align: justify;
  font-family: $font-secundary;
  font-size: 15px;
}

// Titulo usado en quienes somos - valores corporativos
.izquierda {
  display: flex;
  justify-content: flex-end;

  .tituloIzquierda {
    margin-bottom: 15px;
    display: flex;
    background: $color-background;
    border-bottom-left-radius: 30px;
    position: relative;
    padding-left: 20px;

    .linea {
      width: $ancho-linea;
      background-color: $color-primary;
      height: 100%;
      position: absolute;
      right: 15px;
    }

    h2 {
      position: relative;
      font-family: $font-Roboto;
      color: $color-fuentes;
      font-size: 20px;
      margin: 10px 0px;
      margin-right: 5vw;
    }

    .resaltar {
      color: $color-primary;
    }
  }
}

@media screen and (min-width: 992px) {
  .titulo {
    .linea {
      margin-left: 15px;
    }
    h2 {
      font-size: 25px;
    }
  }
  .izquierda {
    .tituloIzquierda {
      h2 {
        font-size: 25px;
      }
    }
  }
}

@media screen and (min-width: 1450px) {
  .titulo {
    h2 {
      font-size: 32px;
    }
  }
  .izquierda {
    .tituloIzquierda {
      h2 {
        font-size: 32px;
      }
    }
  }
}

// Boton cerrar modal
.close {
  display: inline;
  position: absolute;
  z-index: 10;
  right: 0;
  top: 3px;
  color: $color-gris;
  font-size: 25px;
  background-color: transparent;
  border: none;

  span {
    font-weight: bold;
  }

  &:focus {
    outline: none;
  }
}

@media screen and (min-width: 600px) {
  .close {
    display: none;
  }
}
