//Colores

$color-primary: #E77817;
$color-primary2: #F3A25A;
$color-secundary: #23A8BE; // no se ha usado
$color-cuarto: #0E2B2F;
$color-quinto: #4B6063;
$color-gris: #879597;
$color-fuentes: #78888A;
$color-modal: #F6F6F6;
$color-white: #ffffff;
$color-background: #EBEBEB;
$color-border: #C4C4C4;
$color-menu: #E1E4E5;

// Fuentes

@font-face{
    font-family: 'CenturyGothic';
    src: url('../../assets/fuentes/GOTHIC.TTF');
}

$font-primary: Calibri,'Gill Sans', 'Gill Sans MT',  'Trebuchet MS', sans-serif;
$font-secundary: CenturyGothic;
$font-Roboto: 'Roboto', sans-serif;

// Linea de titulos
$ancho-linea: 6px;

section {
    z-index: -999;
}